.modalWrapper {
    position: fixed;
    background-color: rgba(0,0,0,.85);
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .modalInner {
        background-color: #ffffff;
        padding: 2rem;
        .row {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            .col {
                width: 100%;
                margin-top: 1rem;
                flex: 1;
                @media screen and (min-widht: 530px) {
                    margin-top: 0;
                    width: auto;
                }
            }
        }
        h2 {
            margin: 0;
            margin-bottom: 1rem;
        }
    }
}

.beachclub {
    min-width: 280px;
    @media screen and (min-width: 767px) {
        min-width: 355px;
    }
}