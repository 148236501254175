#menu {
    background-color: #272934;
    color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: .5rem 0;
    transition: .2s ease-in-out;
    z-index: 999;
    @media screen and (min-width: 768px) {
        padding: 1.5rem 0;
    }
    #logo {
        svg {
            width: 40px;
            @media screen and (min-width: 768px) {
                width: 50px;
            }
        }
        .st0 {
            fill:#EAD8BB;
        }
    }
    .hamburger {
        padding: 0;
        height: 24px;
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
    #action-button {
        @media screen and (min-width: 768px) {
            .btn {
                display: none;
            }
        }
        @media screen and (min-width: 1200px) {
            padding-left: 1rem;
            .btn {
                display: inline-block;
            }
        }
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        border-radius: 0;
        height: 2px;
        background-color: #ffffff;
    }
    .container-fluid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    #nav-list {
        display: none;
        align-items: center;
        li {
            padding: 0 1.8rem;
            @media screen and (max-width: 1400px) {
                padding: 0 1rem;
            }
            @media screen and (max-width: 1200px) {
                padding: 0 1rem;
            }
            @media screen and (max-width: 992px) {
                padding: 0 .5rem;
            }
            &.active {
                a {
                    color: #EAD8BB;
                }
            }
            a {
                color: #ffffff;
                text-decoration: none;
                font-size: 1rem;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;
                @media screen and (max-width: 992px) {
                    font-size: .75rem;
                }
                &:hover {
                    color: #ead8bb;
                }
            }
        }
        @media screen and (min-width: 768px) {
            display: flex;
        }
    }
}

#menu-list {
    background-color: #272934;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    width: 100%;
    z-index: 998;
    transform: translateX(100%);
    transition: .2s ease-in-out;
    &.active {
        transform: translateX(0);
    }
    ul {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        li {
            position: relative;
            text-align: center;
            padding: 0 1rem;
            a {
                color: #ead8bb;
                font-family: "Montserrat", sans-serif;
                font-weight: 900;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 3.5vh;
                transition: .2s ease-in-out;
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        display: none;
    }
}